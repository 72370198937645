import {useState, useEffect} from 'react';

export default function useIsMobile() {
  const [result, setResult] = useState(false)

  function check() {
    setResult(window.innerWidth < 600)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      check()
      window.addEventListener('resize', check)
    }

    return () => {
      check()
      window.removeEventListener('resize', check)
    }
  }, []);

  return result
}
