import styled from "styled-components"

const AspectRatioBoxOuter = styled.div`
    overflow: visible;
    height: 0;
    padding-top: ${({ $aspectWidth = 1, $aspectHeight = 1 }) => ($aspectHeight/$aspectWidth)*100}%;
    position: relative;
    width: 100%;
    max-width: calc(100vw - 2rem);
    box-sizing: border-box;
    @media screen and (max-width: 1080px) {
      margin-bottom: 20px;
    }
`;

const AspectRatioBoxInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

/**
 * <AspectRatioBox $aspectWidth={1920} $aspectHeight={1080} />
 */
const AspectRatioBox = ({ $aspectWidth, $aspectHeight, children }) => (
    <AspectRatioBoxOuter $aspectWidth={$aspectWidth} $aspectHeight={$aspectHeight}>
        <AspectRatioBoxInner>{children}</AspectRatioBoxInner>
    </AspectRatioBoxOuter>
)

export default AspectRatioBox;
