import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getUnitName, numberWithCommas } from '../../../../utils/currency';
import { Box, Composition, Only, useResponsiveValue } from 'atomic-layout';
import { GraphCard, WarningCard } from 'components/atoms/Cards';
import {
  formatWithLetters,
  toAbbreviatedCurrencyString,
  toCurrencyString,
} from 'utils/currency';

import AspectRatioBox from 'components/atoms/AspectRatioBox';
import { Decimal } from 'decimal.js';
import React from 'react';
import { Switch } from 'components/atoms/Switch';
import format from 'date-fns/format';
import { resample } from 'utils/resampling';
import styled from 'styled-components';
import { useCalculation } from 'components/content/calculator/calculationContext';
import { useResizeDetector } from 'react-resize-detector';
import useTheme from 'utils/useTheme';
import useIsMobile from '../../../../utils/useIsMobile';
import { SATOSHI_FACTOR } from '../../../../utils/convertBtcSats';

const graphDateFormat =
  ({ sampleFrequency }) =>
  (date) => {
    if (sampleFrequency === 'DAILY' || sampleFrequency === 'WEEKLY')
      return format(date, 'do MMM yy');
    if (sampleFrequency === 'YEARLY') return format(date, 'yyyy');
    return format(date, 'MMM yy');
  };

const CustomTick = ({ theme, x, y, stroke, payload, tickFormatter }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill={theme.colors.text}
        transform="rotate(-35)"
      >
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};

const EmptyText = styled.p`
  text-align: center;
`;

const ResizingBarChart = ({ ...props }) => {
  const { width, height, ref } = useResizeDetector();

  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <BarChart {...props} width={width} height={height} />
    </div>
  );
};

const getMaxBarSize = (numBars) => {
  if (numBars < 8) return 100;
  if (numBars < 20) return 45;
  return 30;
};

const CustomLegend = (props) => {
  return (
    <Legend {...props} content={null} payload={[...props.payload].reverse()} />
  );
};

const BaseGraph = ({
  data,
  children,
  sampleFrequency,
  graphProps,
  isFiat = false,
}) => {
  const { startDate, goalDate, isSatoshis } = useCalculation();
  const theme = useTheme();
  const legendAlign = useResponsiveValue({ xs: 'center' }, 'center');

  const btcPayload = [
    {
      id: 1,
      type: 'square',
      value: isSatoshis
        ? graphProps.cryptoGraphStackedSats
        : graphProps.cryptoGraphStackedBTC,
      color: theme.colors.secondaryGraphColor,
    },
    {
      id: 2,
      type: 'square',
      value: isSatoshis
        ? graphProps.cryptoGraphAccumulatedSats
        : graphProps.cryptoGraphAccumulatedBTC,
      color: theme.colors.primary,
    },
  ];

  const fiatPayload = [
    {
      id: 1,
      type: 'square',
      value: graphProps.fiatGraphRunningInvestmentGain,
      color: theme.colors.secondaryGraphColor,
    },
    {
      id: 2,
      type: 'square',
      value: graphProps.fiatGraphInvestmentGain,
      color: theme.colors.tertiaryGraphColor,
    },
  ];

  const legendPayload = isFiat ? fiatPayload : btcPayload;

  const hasCurrentStack = data[0] && data[0].currentStackedCrypto > 0;

  if (hasCurrentStack) {
    const fiatPayloadValue = graphProps.fiatGraphTotalInvested;
    const btcPayloadValue = isSatoshis
      ? graphProps.currentStackSats
      : graphProps.currentStackBTC;
    legendPayload.unshift({
      id: 3,
      type: 'square',
      value: isFiat ? fiatPayloadValue : btcPayloadValue,
      color: isFiat ? theme.colors.primary : theme.colors.graphColor,
    });
  }

  return data.length > 0 ? (
    <>
      <ResizingBarChart
        data={data}
        barGap={0}
        maxBarSize={getMaxBarSize(data.length)}
        barCategoryGap={6}
        margin={{ top: 0, right: 0, left: 0, bottom: 43 }}
      >
        <Legend
          payload={legendPayload}
          verticalAlign="top"
          align={legendAlign}
          content={<CustomLegend />}
          wrapperStyle={{
            paddingLeft: '25px',
            paddingBottom: '10px',
          }}
        />
        <XAxis
          dataKey="date"
          domain={[startDate, goalDate]}
          tickFormatter={graphDateFormat({ sampleFrequency })}
          tick={<CustomTick theme={theme} />}
          interval={data.length > 24 ? 'preserveStartEnd' : 0}
          stroke={theme.colors.text}
        />
        {children}
      </ResizingBarChart>
    </>
  ) : (
    <Box
      flex
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxHeightSmDown={25}
      style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}
    >
      <EmptyText>{graphProps.fallbackText}</EmptyText>
    </Box>
  );
};

const PositiveNegativeBar = (theme, dataKey) =>
  function PositiveNegativeBar(props) {
    const { fill } = props;
    const value = props[dataKey];
    const negativeFill = theme.colors.warningText;
    return <Rectangle {...props} fill={value >= 0 ? fill : negativeFill} />;
  };

const roundToSigFigs = (value, sigfigs) => {
  return Number(Number(value).toPrecision(sigfigs));
};

const TooltipWrapper = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
  min-width: 100px;
`;

const TooltipSeries = styled.p(({ theme, $color }) => ({
  display: 'block',
  color: theme.colors[$color || 'text'],
  margin: 0,
}));

const FiatGraphTooltip = (props) => {
  const { graphProps, label, labelFormatter, formatter } = props;
  const payload = props.payload[0]?.payload || {};
  const colourValueZero = 'textInverse';
  const colourValuePositive = 'tertiaryGraphColor';
  const colourValueNegative = 'warningText';

  const epsilon = 1e-14;
  const tooltipColour =
    Math.abs(payload.fiatGain) < epsilon
      ? colourValueZero
      : payload.fiatGain > 0
      ? colourValuePositive
      : colourValueNegative;

  return (
    <TooltipWrapper>
      <label>{label && labelFormatter(label)}</label>
      <TooltipSeries $color="primary">
        {graphProps.fiatGraphTotalInvested}:{' '}
        {formatter(payload.cumulativeInvestedFiat)}
      </TooltipSeries>
      <TooltipSeries $color={tooltipColour}>
        {graphProps.fiatGraphInvestmentGain}: {formatter(payload.fiatGain)}
      </TooltipSeries>
      <TooltipSeries
        $color={
          payload.runningGain >= 0 ? 'secondaryGraphColor' : 'warningText'
        }
      >
        {graphProps.fiatGraphRunningInvestmentGain}:{' '}
        {formatter(payload.runningGain)}
      </TooltipSeries>
      <TooltipSeries $color="tooltipInverse">
        {graphProps.fiatGraphTotalValue}:{' '}
        {formatter(payload.cumulativeTotalFiat)}
      </TooltipSeries>
    </TooltipWrapper>
  );
};

const FiatGraph = ({ data, graphProps, sampleFrequency }) => {
  const { currency } = useCalculation();
  const theme = useTheme();
  const dataMax = React.useMemo(
    () => Math.max(...data.map((row) => row.cumulativeTotalFiat)),
    [data]
  );
  const topTick = roundToSigFigs(dataMax, 2);

  return (
    <BaseGraph
      data={data}
      sampleFrequency={sampleFrequency}
      graphProps={graphProps}
      isFiat
    >
      <Bar
        stackId="1"
        name={graphProps.fiatGraphTotalInvested}
        dataKey="cumulativeInvestedFiat"
        fill={theme.colors.primary}
      />
      <Bar
        stackId="1"
        name={graphProps.fiatGraphRunningInvestmentGain}
        dataKey="runningGainMinusFiatGain"
        fill={theme.colors.secondaryGraphColor}
        shape={PositiveNegativeBar(theme, 'runningGainMinusFiatGain')}
      />
      <Bar
        stackId="1"
        name={graphProps.fiatGraphInvestmentGain}
        dataKey="fiatGain"
        fill={theme.colors.tertiaryGraphColor}
        shape={PositiveNegativeBar(theme, 'fiatGain')}
      />
      <YAxis
        domain={[0, topTick]}
        tick={{ width: 30 }}
        ticks={[topTick * 0.25, topTick * 0.5, topTick * 0.75, topTick]}
        tickFormatter={(value) =>
          toAbbreviatedCurrencyString(value, currency, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 2,
          })
        }
        stroke={theme.colors.text}
      />
      <Tooltip
        labelFormatter={graphDateFormat({ sampleFrequency })}
        formatter={(value) => {
          return toCurrencyString(value, currency, {
            maximumFractionDigits: 2,
          });
        }}
        cursor={{ fill: '#ffffff25' }}
        labelStyle={{ color: theme.colors.textInverse }}
        contentStyle={{ backgroundColor: theme.colors.text }}
        content={<FiatGraphTooltip graphProps={graphProps} />}
      />
    </BaseGraph>
  );
};

const CryptoGraphTooltip = (props) => {
  const { isSatoshis, currentStack } = useCalculation();
  const { graphProps, label, labelFormatter, formatter } = props;
  const payload = props.payload[0]?.payload || {};

  return (
    <TooltipWrapper>
      <label>{label && labelFormatter(label)}</label>
      <TooltipSeries $color={'secondaryGraphColor'}>
        {isSatoshis
          ? graphProps.cryptoGraphStackedSats
          : graphProps.cryptoGraphStackedBTC}
        : {formatter(payload.stackedCrypto)}
      </TooltipSeries>

      {payload.totalMinusStackedCrypto ? (
        <TooltipSeries $color={'primary'}>
          {isSatoshis
            ? graphProps.cryptoGraphAccumulatedSats
            : graphProps.cryptoGraphAccumulatedBTC}
          : {formatter(payload.totalMinusStackedCrypto)}
        </TooltipSeries>
      ) : (
        <></>
      )}

      {currentStack && currentStack !== '0' ? (
        <TooltipSeries $color={'graphColor'}>
          {isSatoshis
            ? graphProps.currentStackSats
            : graphProps.currentStackBTC}
          : {numberWithCommas(formatter(currentStack))}
        </TooltipSeries>
      ) : null}

      <TooltipSeries $color={'tooltipInverse'}>
        {isSatoshis
          ? graphProps.cryptoGraphTotalSats
          : graphProps.cryptoGraphTotalBTC}
        : {formatter(payload.cumulativeTotalCrypto)}
      </TooltipSeries>
    </TooltipWrapper>
  );
};

const CryptoGraph = ({ data, graphProps, sampleFrequency }) => {
  let { isSatoshis, currentStack } = useCalculation();
  currentStack =
    currentStack === '' || typeof currentStack === 'undefined'
      ? 0
      : currentStack;

  const theme = useTheme();

  const dataInSats = data.map((graphData) => ({
    ...graphData,
    stackedCrypto: graphData.stackedCrypto * SATOSHI_FACTOR,
    cumulativeTotalCrypto: graphData.cumulativeTotalCrypto * SATOSHI_FACTOR,
  }));

  const dataInUnit = isSatoshis ? dataInSats : data;

  const cryptoAdjustedData = React.useMemo(() => {
    return dataInUnit.map((point, index) => {
      const totalMinusStackedCrypto =
        point.cumulativeTotalCrypto - point.stackedCrypto - currentStack;

      return {
        ...point,
        currentStackedCrypto: currentStack,
        stackedCrypto: point.stackedCrypto,
        totalMinusStackedCrypto:
          totalMinusStackedCrypto >= 0 ? totalMinusStackedCrypto : 0,
      };
    });
  }, [data]);

  const dataMax = React.useMemo(
    () => Math.max(...dataInUnit.map((row) => row.cumulativeTotalCrypto)),
    [data]
  );

  const topTick = roundToSigFigs(dataMax, 2);

  return (
    <BaseGraph
      data={cryptoAdjustedData}
      sampleFrequency={sampleFrequency}
      graphProps={graphProps}
    >
      <Bar
        stackId="1"
        name={
          isSatoshis ? graphProps.currentStackSats : graphProps.currentStackBTC
        }
        dataKey="currentStackedCrypto"
        fill={theme.colors.graphColor}
      />
      <Bar
        stackId="1"
        name={
          isSatoshis
            ? graphProps.cryptoGraphAccumulatedSats
            : graphProps.cryptoGraphAccumulatedBTC
        }
        dataKey="totalMinusStackedCrypto"
        fill={theme.colors.primary}
      />
      <Bar
        stackId="1"
        name={
          isSatoshis
            ? graphProps.cryptoGraphStackedSats
            : graphProps.cryptoGraphStackedBTC
        }
        dataKey="stackedCrypto"
        fill={theme.colors.secondaryGraphColor}
      />

      <YAxis
        domain={[0, topTick]}
        tick={{ width: 30 }}
        ticks={[topTick * 0.25, topTick * 0.5, topTick * 0.75, topTick]}
        tickFormatter={(value) =>
          value
            ? formatWithLetters(value, {
                maximumFractionDigits: isSatoshis ? 0 : 3,
                minimunSignificantDigits: 2,
              }) +
              '\n' +
              getUnitName(value, isSatoshis)
            : ''
        }
        stroke={theme.colors.text}
      />
      <Tooltip
        labelFormatter={graphDateFormat({ sampleFrequency })}
        formatter={(value) => {
          return value
            ? value.toLocaleString(undefined, {
                maximumFractionDigits: isSatoshis ? 0 : 8,
              })
            : '';
        }}
        cursor={{ fill: '#ffffff25' }}
        labelStyle={{ color: theme.colors.tooltipInverse }}
        contentStyle={{ backgroundColor: theme.colors.text }}
        content={<CryptoGraphTooltip graphProps={graphProps} />}
      />
    </BaseGraph>
  );
};
const graphs = `
    supplyLimitWarning  toggle
    mobileWarning       mobileWarning
    graph               graph
`;

const Graphs = ({ graphProps, graphToggleProps }) => {
  const isMobile = useIsMobile();
  const [fiatToggle, setFiatToggle] = React.useState(false);
  const {
    tableData,
    stackingFrequency,
    numTimePeriods,
    startDate,
    breachedLimit,
  } = useCalculation();

  const { graphData, sampleFrequency } = React.useMemo(() => {
    let baseData = tableData;
    let sampleFrequency = stackingFrequency;

    if (
      stackingFrequency === 'DAILY' &&
      numTimePeriods > 15 &&
      numTimePeriods < 100
    ) {
      sampleFrequency = 'WEEKLY';
      baseData = resample(tableData, 'DAILY', sampleFrequency, startDate);
    }
    if (
      stackingFrequency === 'DAILY' &&
      numTimePeriods >= 100 &&
      numTimePeriods < 1100
    ) {
      sampleFrequency = 'MONTHLY';
      baseData = resample(tableData, 'DAILY', sampleFrequency, startDate);
    }
    if (stackingFrequency === 'DAILY' && numTimePeriods >= 1100) {
      sampleFrequency = 'YEARLY';
      baseData = resample(tableData, 'DAILY', sampleFrequency, startDate);
    }

    if (
      stackingFrequency === 'WEEKLY' &&
      numTimePeriods > 15 &&
      numTimePeriods < 155
    ) {
      sampleFrequency = 'MONTHLY';
      baseData = resample(tableData, 'WEEKLY', sampleFrequency, startDate);
    }
    if (stackingFrequency === 'WEEKLY' && numTimePeriods >= 155) {
      sampleFrequency = 'YEARLY';
      baseData = resample(tableData, 'WEEKLY', sampleFrequency, startDate);
    }

    if (stackingFrequency === 'MONTHLY' && numTimePeriods >= 12 * 3) {
      sampleFrequency = 'YEARLY';
      baseData = resample(tableData, 'MONTHLY', sampleFrequency, startDate);
    }

    let graphData = (baseData || []).map((data) => {
      const runningGain =
        data.cumulativeTotalFiat - data.cumulativeInvestedFiat;
      return {
        ...data,
        runningGain,
        runningGainMinusFiatGain: runningGain - data.fiatGain,
      };
    });
    graphData = graphData.map((data) => {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          if (value instanceof Decimal) {
            return [key, value.toNumber()];
          }
          return [key, value];
        })
      );
    });
    return {
      graphData,
      sampleFrequency,
    };
  }, [tableData, stackingFrequency, startDate, numTimePeriods]);

  return (
    <GraphCard>
      <Composition
        areas={graphs}
        gap={1}
        width="100%"
        flexGrow={1}
        templateRows="min-content auto"
        style={{ boxSizing: 'border-box' }}
      >
        {(Areas) => (
          <>
            <Areas.Toggle flex justifyContent="flex-end" alignItems="center">
              <Switch
                id="crypto-fiat-graph-switch"
                leftLabel={graphToggleProps?.cryptoLabel || 'Bitcoin'}
                rightLabel={graphToggleProps?.fiatLabel || 'Fiat'}
                checked={fiatToggle}
                onChange={(e) => setFiatToggle(e.target.checked)}
              />
            </Areas.Toggle>
            <Areas.Graph
              flexDirection="column"
              width="113%"
              widthSm="unset"
              marginLeft="-15px"
              marginLeftSm="-10px"
              marginLeftMd="-5px"
              flex
            >
              <AspectRatioBox
                style={{ overflow: 'visible' }}
                $aspectWidth={16}
                $aspectHeight={isMobile ? 10 : 9}
              >
                {!!fiatToggle && (
                  <FiatGraph
                    data={graphData}
                    graphProps={graphProps}
                    sampleFrequency={sampleFrequency}
                  />
                )}
                {!fiatToggle && (
                  <CryptoGraph
                    data={graphData}
                    graphProps={graphProps}
                    sampleFrequency={sampleFrequency}
                  />
                )}
              </AspectRatioBox>
            </Areas.Graph>
            {(graphData || []).length > 10 && (
              <Areas.MobileWarning>
                <Only for="xs" flex alignItems="center" justifyContent="center">
                  <p>{graphProps.smallScreenWarningText}</p>
                </Only>
              </Areas.MobileWarning>
            )}
            {breachedLimit && (
              <Areas.SupplyLimitWarning>
                <WarningCard>
                  <p>{graphProps.overlimitWarningText}</p>
                </WarningCard>
              </Areas.SupplyLimitWarning>
            )}
          </>
        )}
      </Composition>
    </GraphCard>
  );
};

export default Graphs;
