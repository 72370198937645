import styled from 'styled-components';

const switchWidth = 120;

const OnOffSwitch = styled.div`
  position: relative;
  width: ${switchWidth}px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const OnOffSwitchCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &:checked + label + span {
    margin-left: 0;
  }

  &:checked + label,
  span:last-child {
    right: 0px;
  }
`;

const OnOffSwitchLabel = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  ${({ theme }) => theme.glassEffect.border};
  -webkit-border-radius: 50px;
  -khtml-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
`;

const OnOffSwitchInner = styled.span`
  display: block;
  width: 200%;
  margin-left: ${({ $checked }) => ($checked ? 0 : '-100%')};
  transition: margin 0.3s ease-in 0s;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    box-sizing: border-box;
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
  }

  &:before {
    content: attr(data-before-content);
    padding-left: 10px;
    backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
    -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
    background-color: ${({ theme }) => theme.colors.switchBackground};
    color: ${({ theme }) => theme.colors.text};
  }

  &:after {
    content: attr(data-after-content);
    padding-right: 10px;
    backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
    -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
    background-color: ${({ theme }) => theme.colors.switchBackground};
    color: ${({ theme }) => theme.colors.text};
    text-align: right;
  }
`;

const OnOffSwitchSwitch = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  margin: -1px;
  background: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ $checked }) => ($checked ? 0 : `${switchWidth - 20}px`)};
  -webkit-border-radius: 50px;
  -khtml-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  transition: all 0.3s ease-in 0s;
`;

export const Switch = ({ id, leftLabel, rightLabel, onChange, checked }) => {
  return (
    <OnOffSwitch>
      <OnOffSwitchCheckbox
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
      />
      <OnOffSwitchLabel htmlFor={id}>
        <OnOffSwitchInner
          $checked={checked}
          data-before-content={rightLabel}
          data-after-content={leftLabel}
        ></OnOffSwitchInner>
        <OnOffSwitchSwitch $checked={checked}></OnOffSwitchSwitch>
      </OnOffSwitchLabel>
    </OnOffSwitch>
  );
};
